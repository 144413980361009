
import {computed, PropType, SetupContext, toRefs} from "vue";
import {Book, Data, Question} from "@/model/Model";


interface ListItem {
  book: Book,
  question: Question
}

export default {
  name: "SelectedList",
  emits: ["question-selected"],
  props: {
    "books": {
      required: true,
      type: Object as PropType<Map<string, Book>>
    },
    "questions": {
      required: true,
      type: Object as PropType<Map<string, Question>>
    },
    "data": {
      required: true,
      type: Object as PropType<Data>
    }
  },
  setup(props: any, ctx: SetupContext) {
    const {data, questions, books} = toRefs(props)
    const items = computed(() => {
      const items: ListItem[] = []
      for (const questionId in data.value.books) {
        items.push({
          book: books.value.get(data.value.books[questionId]),
          question: questions.value.get(questionId)
        })
      }
      return items.sort((a, b) => a.question.number - b.question.number)
    })

    const questionSelected = (item: ListItem) => ctx.emit("question-selected", item.question)

    return {items, questionSelected}
  }
}
