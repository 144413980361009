
import {PropType} from "vue";

export default {
  name: "ErrorModal",
  emits: ["closed"],
  props: {
    "errors": {
      type: Array as PropType<string[]>
    }
  }
}
