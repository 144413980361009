
import {PropType, SetupContext} from "vue";
import {Class, className, Data} from "@/model/Model";

const classes = [Class.OKTAVA_A, Class.OKTAVA_B, Class.FOURTH_A]

export default {
  name: "GenerateOverview",
  emits: ["class-selected", "name-changed"],
  methods: {className},
  props: {
    data: {
      required: true,
      type: Object as PropType<Data>
    }
  },
  setup(props: any, ctx: SetupContext) {

    const nameSelected = (e: { target: HTMLInputElement }) => {
      ctx.emit("name-changed", e.target.value)
    }

    const classSelected = (e: { target: HTMLSelectElement }) => {
      ctx.emit("class-selected", e.target.value)
    }

    return {classes, classSelected, nameSelected}
  }
}
