

import QuestionComponent from "@/components/QuestionComponent.vue";
import {Book, Class, Question, Question as QuestionType} from "@/model/Model";
import {computed, PropType, ref, watch} from "vue";
import {Clear, Load, Store} from "@/model/Storage";
import SelectedList from "@/components/SelectedList.vue";
import GenerateOverview from "@/components/GenerateOverview.vue";
import {printData, validateData} from "@/model/Print";
import ErrorModal from "@/components/ErrorModal.vue";

export default {
  name: 'App',
  components: {ErrorModal, GenerateOverview, SelectedList, QuestionComponent},
  props: {
    questions: {
      required: true,
      type: Array as PropType<Question[]>
    }
  },
  setup(props: any) {
    const questions: Question[] = props.questions.filter(() => true)
    const mappedQuestions = new Map<string, Question>()
    const mappedBooks = new Map<string, Book>()
    const validationErrors = ref<string[]>([])
    const validationErrorsModalOpened = ref(false)
    questions.forEach(q => {
      mappedQuestions.set(q.id, q)
      q.books.forEach(b => mappedBooks.set(b.id, b))
    })
    const userData = ref(Load(mappedQuestions))
    const index = ref(0);
    const question = computed(() => {
      if (index.value < questions.length) {
        return questions[index.value]
      } else {
        return null
      }
    })
    const book = computed(() => {
      if (index.value < questions.length) {
        const question = questions[index.value]
        return question.books.find(b => b.id === userData.value.books[question.id]) ?? null
      } else {
        return null
      }
    })

    const bookSelected = (question: QuestionType, book: Book) => {
      userData.value.books[question.id] = book.id
    }

    const questionPager = computed(() => {
      return {
        back: index.value > 0,
        next: index.value <= questions.length,
        showOverview: index.value === questions.length,
        toNextPage() {
          if (this.next) {
            index.value++
          }
        },
        toPreviousPage() {
          if (this.back) {
            index.value--
          }
        }
      }
    })

    const questionSelected = (q: Question) => {
      const i = questions.findIndex(question => q.id == question.id)
      if (i > -1) {
        index.value = i
      }
    }

    const deleteData = () => {
      Clear()
      userData.value = Load(mappedQuestions)
      index.value = 0
    }

    const saveData = () => {
      Store(userData.value)
    }

    const showList = computed(() => {
      return Object.getOwnPropertyNames(userData.value.books).length > 0
    })

    watch(userData, () => {
      saveData()
    }, {
      deep: true
    })

    const download = () => {
      const {result, errors} = validateData(userData.value, questions)
      if (!result) {
        validationErrors.value = errors
        validationErrorsModalOpened.value = true
      } else {
        printData(userData.value, questions, mappedBooks)
      }
    }
    return {
      deleteData,
      saveData,
      questionPager,
      question,
      book,
      bookSelected,
      userData,
      mappedBooks,
      mappedQuestions,
      questionSelected,
      nameChanged: (name: string) => userData.value.name = name,
      classSelected: (c: Class) => userData.value.class = c,
      download,
      validationErrors,
      validationErrorsModalOpened,
      showList
    }
  }
}
