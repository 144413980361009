
import {PropType, SetupContext} from "vue";
import {Book, Question} from "@/model/Model";

export default {
  name: "QuestionComponent",
  emits: ["book-selected"],
  props: {
    question: {
      required: true,
      type: Object as PropType<Question>
    },
    selectedBook: {
      required: false,
      type: Object as PropType<Book | null>,
      default: null
    }
  },
  setup: function (props: any, ctx: SetupContext) {
    const selectBook = (book: Book) => {
      ctx.emit("book-selected", book)
    }

    return {selectBook}
  }
}
