import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "List" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "ListItem",
        key: item.question.id
      }, [
        _createElementVNode("h3", {
          class: "ListItemQuestion",
          onClick: _withModifiers(($event: any) => ($setup.questionSelected(item)), ["stop"])
        }, _toDisplayString(item.question.name), 9, _hoisted_2),
        _createElementVNode("div", {
          class: "ListItemAuthor",
          title: item.book.author
        }, _toDisplayString(item.book.author), 9, _hoisted_3),
        _createElementVNode("div", {
          class: "ListItemBook",
          title: item.book.name
        }, _toDisplayString(item.book.name), 9, _hoisted_4)
      ]))
    }), 128))
  ]))
}